import {DIR_LTR, NAV_TYPE_SIDE, SIDE_NAV_LIGHT} from 'constants/ThemeConstant';
import {env} from './EnvironmentConfig';
import {NAV_TYPE_TOP} from "../constants/ThemeConstant";
import {DEFAULT_LANGUAGE, languages} from "../i18n";
import i18n from "i18next";

export const APP_NAME = 'One-Live';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/broadcast/streams`;
export const UNAUTHENTICATED_ENTRY = '/login'
export const DISABLE_REGISTRATION = true;

export const THEME_CONFIG = {
  navCollapsed: localStorage.getItem('navCollapsed') === 'true',
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: languages.filter(language => language.id === localStorage.getItem('locale')).length > 0 ? localStorage.getItem('locale') : DEFAULT_LANGUAGE,
  navType: localStorage.getItem('navType') === NAV_TYPE_TOP ? NAV_TYPE_TOP : NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: localStorage.getItem('currentTheme') === 'light' ? 'light' : 'dark',
  direction: DIR_LTR,
  blankLayout: false,
};

i18n.changeLanguage(THEME_CONFIG.locale);