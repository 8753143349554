import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	statuses: [],
	status: {},

	creating: false,
	deleting: false,
	updating: false,
	gettingList: false,
	gettingItem: false,

	isError: false,
	errorCode: 0,
	errorMessage: null,
};

export const StatusSlice = createSlice({
	name: 'status',
	initialState,
	reducers: {

		clearStatusItem: (state, action) => {
			return { ...state, status: {} };
		},

		onLoadCreate: (state, action ) => {
			return {...state, creating: true};
		},

		onLoadDelete: (state, action ) => {
			return {...state, deleting: true};
		},

		onLoadArchive: (state, action ) => {
			return {...state, deleting: true};
		},

		onLoadUpdate: (state, action ) => {
			return {...state, updating: true};
		},

		onLoadList: (state, action ) => {
			return {...state, gettingList: true};
		},

		onLoadItem: (state, action ) => {
			return {...state, gettingItem: true};
		},

		// Success
		onSuccessCreate: (state, action) => {
			return {...state, creating: false};
		},

		onSuccessDelete: (state, action) => {
			return {...state, deleting: false};
		},

		onSuccessUpdate: (state, action) => {
			return {...state, updating: false};
		},

		onSuccessList: (state, action) => {
			return {...state, statuses: action.payload.data, gettingList: false};
		},

		onSuccessItem: (state, action) => {
			return {...state, status: action.payload.data, gettingItem: false};
		},

		// Errors
		onFailCreate: (state, action) => {
			return {...state, creating: false };
		},

		onFailDelete: (state, action) => {
			return {...state, deleting: false };
		},

		onFailUpdate: (state, action) => {
			return {...state, updating: false };
		},

		onFailList: (state, action) => {
			return {...state, statuses: [] };
		},

		onFailItem: (state, action) => {
			return {...state, status: {} };
		},
	},
});

export const {
	clearStatusItem,
} = StatusSlice.actions;
