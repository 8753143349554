import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	playlists: [],
	playlist: null,

	creating: false,
	deleting: false,
	updating: false,
	gettingList: false,
	gettingItem: false,

	isError: false,
	errorCode: 0,
	errorMessage: null,
};

export const PlaylistSlice = createSlice({
	name: 'playlist',
	initialState,
	reducers: {

		clearPlaylistItem: (state, action) => {
			return { ...state, playlist: {} };
		},

		onLoadCreate: (state, action ) => {
			return {...state, creating: true};
		},

		onLoadDelete: (state, action ) => {
			return {...state, deleting: true};
		},

		onLoadUpdate: (state, action ) => {
			return {...state, updating: true};
		},

		onLoadList: (state, action ) => {
			return {...state, gettingList: true};
		},

		onLoadItem: (state, action ) => {
			return {...state, gettingItem: true};
		},

		// Success
		onSuccessCreate: (state, action) => {
			return {...state, creating: false};
		},

		onSuccessDelete: (state, action) => {
			return {...state, deleting: false};
		},

		onSuccessUpdate: (state, action) => {
			return {...state, updating: false};
		},

		onSuccessList: (state, action) => {
			return {...state, playlists: action.payload.data.data, gettingList: false};
		},

		onSuccessItem: (state, action) => {
			return {...state, playlist: action.payload.data, gettingItem: false};
		},

		// Errors
		onFailCreate: (state, action) => {
			return {...state, creating: false };
		},
		onFailDelete: (state, action) => {
			return {...state, deleting: false };
		},
		onFailUpdate: (state, action) => {
			return {...state, updating: false };
		},
		onFailList: (state, action) => {
			return {...state, playlists: [] };
		},
		onFailItem: (state, action) => {
			return {...state, playlist: null };
		},
	},
});

export const {
	clearPlaylistItem,
} = PlaylistSlice.actions;
