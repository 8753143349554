import {combineReducers} from 'redux';
import {ThemeSlice} from './slices/ThemeSlice';
import {AuthSlice} from './slices/AuthSlice';
import {PaginationSlice} from "./slices/PaginationSlice";
import {SortSlice} from "./slices/SortSlice";
import {FilterSlice} from "./slices/FilterSlice";
import {ProfileSlice} from "./slices/ProfileSlice";
import {ResolutionSlice} from "./slices/ResolutionSlice";
import {SportSlice} from "./slices/SportSlice";
import {ServerSlice} from "./slices/ServerSlice";
import {StreamSlice} from "./slices/StreamSlice";
import {UserSlice} from "./slices/UserSlice";
import {StatusSlice} from "./slices/StatusSlice";
import {PlaylistSlice} from "./slices/PlaylistSlice";

export const slices = combineReducers({
  theme: ThemeSlice.reducer,
  auth: AuthSlice.reducer,
  profile: ProfileSlice.reducer,
  user: UserSlice.reducer,

  // Pagination, sorting, filtering
  pagination: PaginationSlice.reducer,
  sort: SortSlice.reducer,
  filter: FilterSlice.reducer,

  resolution: ResolutionSlice.reducer,
  sport: SportSlice.reducer,
  server: ServerSlice.reducer,
  stream: StreamSlice.reducer,
  status: StatusSlice.reducer,
  playlist: PlaylistSlice.reducer,
});
