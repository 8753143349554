import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	users: [],
	user: {},
	search: [],

	creating: false,
	deleting: false,
	updating: false,
	gettingList: false,
	gettingItem: false,
	searching: false,

	isError: false,
	errorCode: 0,
	errorMessage: null,
};

export const UserSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {

		clearUserItem: (state, action) => {
			return { ...state, user: {} };
		},

		clearUserSearchResult: (state, action) => {
			return { ...state, search: [] };
		},

		// Loading
		onLoadCreate: (state, action ) => {
			return { ...state, creating: true };
		},

		onLoadDelete: (state, action ) => {
			return { ...state, deleting: true };
		},

		onLoadUpdate: (state, action ) => {
			return { ...state, updating: true };
		},

		onLoadList: (state, action ) => {
			return { ...state, gettingList: true };
		},

		onLoadItem: (state, action ) => {
			return { ...state, gettingItem: true };
		},


		onLoadSearch: (state, action ) => {
			return { ...state, search: [], searching: true };
		},

		// Success
		onSuccessCreate: (state, action) => {
			return {
				...state,
				users: [...state.users, action.payload.data],
				creating: false
			};
		},

		onSuccessDelete: (state, action) => {
			return {
				...state,
				users: action.payload.data,
				deleting: false,
			};
		},

		onSuccessUpdate: (state, action) => {
			const buffer = state.users.filter(e=>e.id !== action.payload.data.id);
			buffer.push(action.payload.data);
			return {
				...state,
				users: buffer,
				updating: false,
			};
		},

		onSuccessList: (state, action) => {
			return {
				...state,
				users: action.payload.data.data,
				gettingList: false,
			};
		},

		onSuccessItem: (state, action) => {
			return {
				...state,
				user: action.payload.data,
				gettingItem: false,
			};
		},

		onSuccessSearch: (state, action) => {
			return {
				...state,
				search: action.payload.data,
				searching: false,
			};
		},

		// Errors
		onFailCreate: (state, action) => {
			return { ...state, creating: false };
		},
		onFailDelete: (state, action) => {
			return { ...state, deleting: false };
		},
		onFailUpdate: (state, action) => {
			return { ...state, updating: false };
		},
		onFailList: (state, action) => {
			return { ...state, users: [] };
		},
		onFailItem: (state, action) => {
			return { ...state, user: {} };
		},
		onFailSearch: (state, action) => {
			return { ...state, search: [], searching: false };
		},
	},
});

export const {
	clearUserItem,
	clearUserSearchResult
} = UserSlice.actions;
