import React, { useEffect } from 'react';
import { onBlankLayout } from 'store/slices/ThemeSlice';
import { useDispatch } from 'react-redux';

const AppRoute = ({ component: Component, routeKey, blankLayout, ...props }) => {

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(onBlankLayout(!!blankLayout));
	}, [blankLayout, dispatch]);
	
	return <Component {...props} />;
};

export default AppRoute;