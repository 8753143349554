import {API, Methods} from "store/middlewares/apiMiddleware";
export const SportAPI = {};

const SLICE = 'sport';
const ACTIONS = {
  create: 'create',
  delete: 'delete',
  update: 'update',
  list:   'list',
  item:   'item',
  search: 'search',
};

SportAPI.create = (data, onSuccess, onFailure) => {
  return {
    type:     API,
    url:      `/stream/sport`,
    method:   Methods.POST,
    data:     data,
    slice:     SLICE,
    action:    ACTIONS.create,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

SportAPI.delete = (ids, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/stream/sport`,
    method:    Methods.DELETE,
    data:      {ids},
    slice:     SLICE,
    action:    ACTIONS.delete,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

SportAPI.update = (data, onSuccess, onFailure) => {
  return {
    type:     API,
    url:      `/stream/sport`,
    method:   Methods.PUT,
    data:     data,
    slice:     SLICE,
    action:    ACTIONS.update,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

SportAPI.list = (data, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/stream/sports`,
    method:    Methods.GET,
    data:      data,
    slice:     SLICE,
    action:    ACTIONS.list,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

SportAPI.item = (id, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/stream/sport`,
    method:    Methods.GET,
    data:      {id},
    slice:     SLICE,
    action:    ACTIONS.item,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

