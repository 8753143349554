import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	servers: [],
	server: {},
	search: [],

	creating: false,
	deleting: false,
	updating: false,
	gettingList: false,
	gettingItem: false,
	searching: false,

	isError: false,
	errorCode: 0,
	errorMessage: null,
};

export const ServerSlice = createSlice({
	name: 'server',
	initialState,
	reducers: {

		clearServerItem: (state, action) => {
			return { ...state, server: {} };
		},

		clearServerSearchResult: (state, action) => {
			return { ...state, search: [] };
		},

		onLoadCreate: (state, action ) => {
			return {...state, creating: true};
		},

		onLoadDelete: (state, action ) => {
			return {...state, deleting: true};
		},

		onLoadUpdate: (state, action ) => {
			return {...state, updating: true};
		},

		onLoadList: (state, action ) => {
			return {...state, gettingList: true};
		},

		onLoadItem: (state, action ) => {
			return {...state, gettingItem: true};
		},

		onLoadSearch: (state, action ) => {
			return {...state, search: [], searching: true};
		},

		// Success
		onSuccessCreate: (state, action) => {
			return {...state, creating: false};
		},

		onSuccessDelete: (state, action) => {
			return {...state, deleting: false};
		},

		onSuccessUpdate: (state, action) => {
			return {...state, updating: false};
		},

		onSuccessList: (state, action) => {
			return {...state, servers: action.payload.data, gettingList: false};
		},

		onSuccessItem: (state, action) => {
			return {...state, server: action.payload.data, gettingItem: false};
		},

		onSuccessSearch: (state, action) => {
			return {...state, search: action.payload.data, searching: false};
		},

		// Errors
		onFailCreate: (state, action) => {
			return {...state, creating: false };
		},
		onFailDelete: (state, action) => {
			return {...state, deleting: false };
		},
		onFailUpdate: (state, action) => {
			return {...state, updating: false };
		},
		onFailList: (state, action) => {
			return {...state, servers: [] };
		},
		onFailItem: (state, action) => {
			return {...state, server: {} };
		},
		onFailSearch: (state, action) => {
			return { ...state, search: [], searching: false };
		},
	},
});

export const {
	clearServerItem,
	clearServerSearchResult
} = ServerSlice.actions;
