import React, {useEffect} from 'react'
import {Button, Col, Row} from "antd";
import {ArrowLeftOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {onBlankLayout} from "../../store/slices/ThemeSlice";
import {useTranslation} from "react-i18next";
import Footer from "components/layout-components/Footer";

export const Error404 = () => {
  const {t} = useTranslation('error404');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector(state => state.theme.currentTheme);

  useEffect(() => {
    dispatch(onBlankLayout(true));
  }, [dispatch]);

  return (
    <div className={`auth-form ${theme === 'light' ? 'bg-white' : ''}`}>
      <div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1">
        <div>
          <img className="img-fluid" src={`/img/${theme === 'light' ? 'logo.png' : 'logo-white.png'}`} alt=""/>
        </div>
        <div className="container">
          <Row align="middle">
            <Col xs={24} sm={24} md={8}>
              <h1 className="font-weight-bold mb-4 display-4">{t('title')}</h1>
              <p className="font-size-md mb-4">{t('description')}</p>
              <Button type="primary" icon={<ArrowLeftOutlined/>}
                      onClick={() => navigate(-1)}>{t('button.back')}</Button>
            </Col>
            <Col xs={24} sm={24} md={{span: 14, offset: 2}}>
              <img className="img-fluid mt-md-0 mt-4" src="/img/others/img-20.png" alt=""/>
            </Col>
          </Row>
        </div>
        <Footer/>
      </div>
    </div>
  );

};

export default Error404;