import {API, Methods} from "store/middlewares/apiMiddleware";
export const ProfileAPI = {};

const SLICE = 'profile';
const ACTIONS = {
  item: 'item',
};

ProfileAPI.item = (onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/profile`,
    method:    Methods.GET,
    slice:     SLICE,
    action:    ACTIONS.item,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};
