import {API, Methods} from "store/middlewares/apiMiddleware";
export const PlaylistAPI = {};

const SLICE = 'playlist';
const ACTIONS = {
  create: 'create',
  delete: 'delete',
  update: 'update',
  list:   'list',
  item:   'item',
};

PlaylistAPI.create = (data, onSuccess, onFailure) => {
  return {
    type:     API,
    url:      `/profile/playlist`,
    method:   Methods.POST,
    data:     data,
    slice:     SLICE,
    action:    ACTIONS.create,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

PlaylistAPI.delete = (ids, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/profile/playlist`,
    method:    Methods.DELETE,
    data:      {ids},
    slice:     SLICE,
    action:    ACTIONS.delete,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

PlaylistAPI.update = (data, onSuccess, onFailure) => {
  return {
    type:     API,
    url:      `/profile/playlist`,
    method:   Methods.PUT,
    data:     data,
    slice:     SLICE,
    action:    ACTIONS.update,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

PlaylistAPI.list = (data, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/profile/playlists`,
    method:    Methods.GET,
    data:      data,
    slice:     SLICE,
    action:    ACTIONS.list,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

PlaylistAPI.item = (id, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/profile/playlist`,
    method:    Methods.GET,
    data:      {id},
    slice:     SLICE,
    action:    ACTIONS.item,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};
