import {API, Methods} from "store/middlewares/apiMiddleware";
export const ResolutionAPI = {};

const SLICE = 'resolution';
const ACTIONS = {
  create: 'create',
  delete: 'delete',
  update: 'update',
  list:   'list',
  item:   'item',
  search: 'search',
};

ResolutionAPI.create = (data, onSuccess, onFailure) => {
  return {
    type:     API,
    url:      `/stream/resolution`,
    method:   Methods.POST,
    data:     data,
    slice:     SLICE,
    action:    ACTIONS.create,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

ResolutionAPI.delete = (ids, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/stream/resolution`,
    method:    Methods.DELETE,
    data:      {ids},
    slice:     SLICE,
    action:    ACTIONS.delete,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

ResolutionAPI.update = (data, onSuccess, onFailure) => {
  return {
    type:     API,
    url:      `/stream/resolution`,
    method:   Methods.PUT,
    data:     data,
    slice:     SLICE,
    action:    ACTIONS.update,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

ResolutionAPI.list = (data, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/stream/resolutions`,
    method:    Methods.GET,
    data:      data,
    slice:     SLICE,
    action:    ACTIONS.list,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

ResolutionAPI.item = (id, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/stream/resolution`,
    method:    Methods.GET,
    data:      {id},
    slice:     SLICE,
    action:    ACTIONS.item,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

ResolutionAPI.search = (name, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/stream/resolutions`,
    method:    Methods.POST,
    data:      {name},
    slice:     SLICE,
    action:    ACTIONS.search,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};
