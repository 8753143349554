import {API, Methods} from "store/middlewares/apiMiddleware";
export const StreamAPI = {};

const SLICE = 'stream';
const ACTIONS = {
  create:  'create',
  delete:  'delete',
  update:  'update',
  list:    'list',
  item:    'item',
  search:  'search',
  restore: 'restore',
  import:  'import',
};

StreamAPI.create = (data, onSuccess, onFailure) => {
  return {
    type:     API,
    url:      `/stream`,
    method:   Methods.POST,
    data:     data,
    slice:     SLICE,
    action:    ACTIONS.create,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

StreamAPI.delete = (ids, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/stream`,
    method:    Methods.DELETE,
    data:      {ids},
    slice:     SLICE,
    action:    ACTIONS.delete,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

StreamAPI.restore = (ids, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/archive`,
    method:    Methods.DELETE,
    data:      {ids},
    slice:     SLICE,
    action:    ACTIONS.restore,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

StreamAPI.update = (data, onSuccess, onFailure) => {
  return {
    type:     API,
    url:      `/stream`,
    method:   Methods.PUT,
    data:     data,
    slice:     SLICE,
    action:    ACTIONS.update,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

StreamAPI.list = (archive, monitoring, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/streams`,
    method:    Methods.GET,
    data:      {
      archive: archive,
      monitoring: monitoring,
    },
    slice:     SLICE,
    action:    ACTIONS.list,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

StreamAPI.item = (id, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/stream`,
    method:    Methods.GET,
    data:      {id},
    slice:     SLICE,
    action:    ACTIONS.item,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

StreamAPI.search = (name, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/streams`,
    method:    Methods.POST,
    data:      {name},
    slice:     SLICE,
    action:    ACTIONS.search,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

StreamAPI.import = (file, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/streams/import`,
    method:    Methods.POST,
    data:      file,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    slice:     SLICE,
    action:    ACTIONS.import,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};
