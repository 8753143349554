import axios from "axios";
import _ from "lodash";
import {API_BASE_URL} from "configs/AppConfig";
import i18n from "i18next";
import store from "../index";
export const API = 'API';

export const Methods = {
  GET:    'GET',
  POST:   'POST',
  PUT:    'PUT',
  DELETE: 'DELETE'
};

const AllowedMethods = [
  Methods.GET,
  Methods.POST,
  Methods.PUT,
  Methods.DELETE
];

/**
 *
 * @param dispatch
 * @returns {function(*): function(*=): (*|undefined)}
 */
export const apiMiddleware = ({ dispatch }) => next => nextAction => {

  // Если тип события не API, то
  if( nextAction.type !== API ) {

    // Не обрабатываем запрос
    return next(nextAction);
  }

  // Полезная нагрузка
  let payload = {
    data: null,
    isError: false,
    isLoading: true,
    errorCode: 0,
    errorMessage: null,
  }

  // Получаем необходимые параметры из action
  const {
    url,        // Адрес
    method,     // Метод {GET, POST, PUT, DELETE}
    data,       // Передаваемые данные
    slice,      // Название слайса (Redux Toolkit)
    action,     // Название экшена (Redux Toolkit)
    headers,    // Http-Заголовки
    onSuccess,  // Callback функция при успехе выполнения запроса
    onFailure,  // Callback функция при ошибке выполнения запроса
  } = nextAction;


  let customHeaders = headers;
  if( typeof customHeaders === 'undefined' ) {
    customHeaders = {};
  }

  let sliceName = null;
  let actionName = null;
  if( _.isString(slice) && slice.length > 0 ) {
    sliceName = slice;
  }

  if( _.isString(action) && action.length > 0 ) {
    actionName = action.charAt(0).toUpperCase() + action.slice(1);
  }

  // Если указанный метод не {GET, POST, PUT, DELETE}, то
  if( !~AllowedMethods.indexOf(method) ) {

    // Не обрабатываем данный экшен
    return next(nextAction);
  }

  // Если это метод GET или DELETE, то
  const dataType = ['GET', 'DELETE'].includes(method)
    ? 'params' // Передаем данные через query string
    : 'data';  // В противном случае передаем через body

  // Настройка axios
  axios.defaults.timeout = 60000;

  // Если токен существует, то будем посылать заголовок с авторизацией
  const token = localStorage.getItem('token');

  if( typeof token === "string" && token.length ) {
    customHeaders['Authorization'] = `Bearer ${token}`;
  }

  customHeaders['Language'] = i18n.language;

  const reduxState = store.getState();

  // Pagination, sorting and filtering headers
  if( !_.isNil(reduxState) && _.isObject(reduxState) ) {

    // Pagination
    if( _.has(reduxState, 'pagination') ) {
      const pagination = reduxState.pagination;
      if( _.has(pagination, 'pageCurrent') && _.has(pagination, 'pageSize') ) {
        if( _.isNumber(pagination.pageCurrent) && _.isNumber(pagination.pageSize) ) {
          customHeaders['Page-Current'] = pagination.pageCurrent;
          customHeaders['Page-Size'] = pagination.pageSize;
        }
      }
    }

    // Sorting
    if( _.has(reduxState, 'sort') ) {
      const sort = reduxState.sort;
      if( _.has(sort, 'sortColumn') && _.has(sort, 'sortOrder') ) {
        if( _.isString(sort.sortColumn) && sort.sortColumn.length > 0 ) {
          if( _.isString(sort.sortOrder) ) {
            if( sort.sortOrder.toLowerCase() === 'ascend' || sort.sortOrder.toLowerCase() === 'descend' ) {
              customHeaders['Sort-Column'] = sort.sortColumn;
              customHeaders['Sort-Order'] = sort.sortOrder;
            }
          }
        }
      }
    }

    // Filtering
    if( _.has(reduxState, 'filter') ) {
      const filter = reduxState.filter;
      if( _.has(filter, 'filterColumn') && _.has(filter, 'filterValue') ) {
        if( !_.isNil(filter.filterColumn) && _.isString(filter.filterColumn) && filter.filterColumn?.length > 0 &&
            !_.isNil(filter.filterValue) && _.isString(filter.filterValue) && filter.filterValue?.length > 0 ) {
          customHeaders['Filter-Column'] = filter.filterColumn;
          customHeaders['Filter-Value'] = encodeURI(filter.filterValue);
        }
      }
    }
  }

  // Создаем запрос
  const request = {
    url: `${url}`,
    method: method,
    baseURL: `${API_BASE_URL}`,

    // `transformRequest` allows changes to the request data before it is sent to the server
    // This is only applicable for request methods 'PUT', 'POST', 'PATCH' and 'DELETE'
    // The last function in the array must return a string or an instance of Buffer, ArrayBuffer,
    // FormData or Stream
    // You may modify the headers object.
    // transformRequest: [function (data, headers) {
    //   // Do whatever you want to transform the data
    //
    //   return data;
    // }],

    // `transformResponse` allows changes to the response data to be made before
    // it is passed to then/catch
    // transformResponse: [function (data) {
    //   // Do whatever you want to transform the data
    //
    //   return data;
    // }],

    // `headers` are custom headers to be sent
    headers: customHeaders,

    // `params` are the URL parameters to be sent with the request
    // Must be a plain object or a URLSearchParams object
    // NOTE: params that are null or undefined are not rendered in the URL.
    // params: {
    //   ID: 12345
    // },

    // `paramsSerializer` is an optional function in charge of serializing `params`
    // (e.g. https://www.npmjs.com/package/qs, http://api.jquery.com/jquery.param/)
    // paramsSerializer: function (params) {
    //   return Qs.stringify(params, {arrayFormat: 'brackets'})
    // },

    // `data` is the data to be sent as the request body
    // Only applicable for request methods 'PUT', 'POST', 'DELETE', and 'PATCH'
    // When no `transformRequest` is set, must be of one of the following types:
    // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
    // - Browser only: FormData, File, Blob
    // - Node only: Stream, Buffer
    // data: {
    //   firstName: 'Fred'
    // },

    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 0, // default is `0` (no timeout)

    // `withCredentials` indicates whether or not cross-site Access-Control requests
    // should be made using credentials
    withCredentials: false, // default

    // `responseType` indicates the type of data that the server will respond with
    // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
    // browser only: 'blob'
    responseType: 'json', // default

    // `responseEncoding` indicates encoding to use for decoding responses (Node.js only)
    // Note: Ignored for `responseType` of 'stream' or client-side requests
    responseEncoding: 'utf8', // default

    // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
    xsrfCookieName: 'XSRF-TOKEN',

    // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
    xsrfHeaderName: 'X-XSRF-TOKEN',

    // `onUploadProgress` allows handling of progress events for uploads
    // browser only
    onUploadProgress: function (progressEvent) {
      // Do whatever you want with the native progress event
    },

    // `onDownloadProgress` allows handling of progress events for downloads
    // browser only
    onDownloadProgress: function (progressEvent) {
      // Do whatever you want with the native progress event
    },

    // `maxContentLength` defines the max size of the http response content in bytes allowed in node.js
    // maxContentLength: 2000,

    // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
    // maxBodyLength: 2000,

    // `validateStatus` defines whether to resolve or reject the promise for a given
    // HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
    // or `undefined`), the promise will be resolved; otherwise, the promise will be
    // rejected.
    // validateStatus: function (status) {
    //   return status >= 200 && status < 300; // default
    // },

    // `maxRedirects` defines the maximum number of redirects to follow in node.js.
    // If set to 0, no redirects will be followed.
    maxRedirects: 5, // default

    // `socketPath` defines a UNIX Socket to be used in node.js.
    // e.g. '/var/run/docker.sock' to send requests to the docker daemon.
    // Only either `socketPath` or `proxy` can be specified.
    // If both are specified, `socketPath` is used.
    socketPath: null, // default

    // `httpAgent` and `httpsAgent` define a custom agent to be used when performing http
    // and https requests, respectively, in node.js. This allows options to be added like
    // `keepAlive` that are not enabled by default.
    // httpAgent: new http.Agent({ keepAlive: true }),
    // httpsAgent: new https.Agent({ keepAlive: true }),

    // `cancelToken` specifies a cancel token that can be used to cancel the request
    // (see Cancellation section below for details)
    // cancelToken: new CancelToken(function (cancel) {
    // }),

    // `decompress` indicates whether or not the response body should be decompressed
    // automatically. If set to `true` will also remove the 'content-encoding' header
    // from the responses objects of all decompressed responses
    // - Node only (XHR cannot turn off decompression)
    decompress: true, // default

    [dataType]: data,
  };

  // Экшен, который будет отправлен
  //const actionType = `${slice}/${action}`;

  // Сигнализируем о начале запроса
  payload.isLoading = true;
  if( !_.isNil(slice) && !_.isNil(actionName) ) {
    dispatch({ type: `${sliceName}/onLoad${actionName}`, payload: {...payload}});
  }

  // Если запрос выполнен успешно
  axios.request(request).then( ({data, status, statusText}) => {

    // Формируем полезную нагрузку
    payload.data         = data;
    payload.isLoading    = false;
    payload.isError      = false;
    payload.errorCode    = status;
    payload.errorMessage = statusText;

    if( _.isObject(data) ) {
      payload = {...payload, data: data};

      // Pagination...
      if( _.has(payload.data, 'current_page') && _.isNumber(payload.data.current_page)) {
        dispatch({type: 'pagination/setPageCurrent', payload: payload.data.current_page})
      }

      if( _.has(payload.data, 'per_page') && _.isNumber(payload.data.per_page)) {
        dispatch({type: 'pagination/setPageSize', payload: payload.data.per_page})
      }

      if( _.has(payload.data, 'total') && _.isNumber(payload.data.total)) {
        dispatch({type: 'pagination/setPageTotal', payload: payload.data.total})
      }
    }

    // // Отправляем событие в redux
    if( !_.isNil(sliceName) && !_.isNil(actionName) ) {
      dispatch({type: `${sliceName}/onSuccess${actionName}`, payload: payload});
    }

    // // Если параметр onSuccess, является функцией, то вызываем ее, передавая payload
    if( typeof onSuccess === "function" ) {
      onSuccess(payload);
    }

  // Обработка исключений
  }).catch( (error) => {

    // Формируем полезную нагрузку
    payload.isLoading     = false;
    payload.isError       = true;
    payload.errorCode     = '';
    payload.errorMessage  = null;
    payload.errorMessages = [];
    // Если есть данные от сервера, то
    if( _.isObject(error) && _.has(error, 'response') && !_.isUndefined(error.response)) {

      if( _.isObject(error) && _.has(error, 'response') && _.isObject(error.response) ) {

        // Parse error response data
        if( _.isObject(error.response.data) ) {
          if( _.has(error.response.data, 'message') &&
              _.isString(error.response.data.message) &&
              error.response.data.message.toString().length > 0 ) {
            payload.errorMessage = error.response.data.message;
          }

          if( _.has(error.response.data, 'errors') &&
              _.isArray(error.response.data.errors) ){
            payload.errorMessages = error.response.data.errors;
          }
        }
      }

      if( _.has(error.response, 'status') && _.isNumber(error.response.status) ) {
        payload.errorCode = error.response.status;
        if( error.response.status === 401 ) {
          localStorage.removeItem('token');
          window.location = '/';
        }
      }

    } else {
      if( _.isObject(error) && _.has(error, 'message') && _.isString(error.message) ) {
        payload.errorMessage = error.message;
      }

      payload.errorCode = -1;
    }

    if( !_.isNil(sliceName) && !_.isNil(actionName) ) {
      dispatch({type: `${sliceName}/onFail${actionName}`, payload: {...payload}});
    }

    if( payload.errorCode === 500 && !_.isNil(window) && _.has(window, 'location')) {
      //window.location = `${APP_PREFIX_PATH}/error/500`;
    }

    if( typeof onFailure === "function" ) onFailure({...payload });

  });
}

export default apiMiddleware;
