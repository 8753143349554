import {lazy} from "react";
import {APP_PREFIX_PATH} from "../AppConfig";
import RouteAccess from "constants/RouteAccess";

export const ProfileRoutes = [
  {
    key: 'profile_user',
    path: `${APP_PREFIX_PATH}/profile`,
    component: lazy(() => import('views/Profile/User')),
    access: RouteAccess.PRIVATE,
  },
  {
    key: 'profileEdit',
    path: `${APP_PREFIX_PATH}/profile/edit`,
    component: lazy(() => import('views/Profile/User')),
    access: RouteAccess.PRIVATE,
  },
  {
    key: 'profileChangePassword',
    path: `${APP_PREFIX_PATH}/profile/password`,
    component: lazy(() => import('views/Profile/User')),
    access: RouteAccess.PRIVATE,
  },
  {
    key: 'profileBilling',
    path: `${APP_PREFIX_PATH}/profile/billing`,
    component: lazy(() => import('views/Profile/User')),
    access: RouteAccess.PRIVATE,
  },
  {
    key: 'profileNotification',
    path: `${APP_PREFIX_PATH}/profile/notification`,
    component: lazy(() => import('views/Profile/User')),
    access: RouteAccess.PRIVATE,
  },
  {
    key: 'profile_playlist',
    path: `${APP_PREFIX_PATH}/profile/playlist`,
    component: lazy(() => import('views/Profile/Playlist')),
    access: RouteAccess.PRIVATE,
  },
];
