import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	pageCurrent: 1,
	pageSize: 10,
	pageTotal: 0,
};

export const PaginationSlice = createSlice({
	name: 'pagination',
	initialState,
	reducers: {

		setPageCurrent: (state, action ) => {
			return { ...state, pageCurrent: action.payload };
		},

		setPageSize: (state, action ) => {
			return { ...state, pageSize: action.payload };
		},

		setPageTotal: (state, action) => {
			return { ...state, pageTotal: action.payload };
		},

		resetPagination: (state, action) => {
			return { ...state, pageCurrent: 1, pageSize: 10, pageTotal: 0 };
		},
	},
});

export const {
	setPageCurrent,
	setPageSize,
	setPageTotal,
	resetPagination,
} = PaginationSlice.actions;
