import React, {lazy, memo, Suspense, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Loading from 'components/shared-components/Loading';
import Views from 'views';
import _ from "lodash";
import API from "store/api";

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

const Layouts = () => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const blankLayout = useSelector(state => state.theme.blankLayout);
  const direction = useSelector(state => state.theme.direction);
  const navType = useSelector(state => state.theme.navType);
  const navCollapsed = useSelector(state => state.theme.navCollapsed);
  const Layout = token && !blankLayout ? AppLayout : AuthLayout

  useEffect(() => {
    if (_.isString(token) && token.length > 0) {
      dispatch(API.profile.item());
    }
  }, [dispatch, token]);

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Layout direction={direction} navType={navType} navCollapsed={navCollapsed}>
        <Views/>
      </Layout>
    </Suspense>
  )
}

export default memo(Layouts)