import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	profile:       null,
	gettingItem:  false,
	isError:      false,
	errorCode:    0,
	errorMessage: null,
};

export const ProfileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {

		onLoadItem: (state, action ) => {
			return { ...state, gettingItem: true };
		},

		onSuccessItem: (state, action) => {
			return { ...state, profile: action.payload.data, gettingItem: false };
		},

		onFailItem: (state, action) => {
			return { ...state, gettingItem: false };
		},
	},
});

