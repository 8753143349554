import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {AllRoutes} from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import RouteAccess from '../constants/RouteAccess';
import Error404 from "../views/Errors/Error404";

const SiteMap = () => {

  const protectedRoutes = AllRoutes.filter(e=>e.access === RouteAccess.PRIVATE);
  const publicRoutes = AllRoutes.filter(e=>e.access === RouteAccess.PUBLIC);

  return (
    <Routes>
      <Route path="/" element={<PublicRoute />}>
        {
          publicRoutes.map(route => {
            const e = <AppRoute routeKey={route.key} component={route.component} {...route.meta} />;
            return <Route key={route.path} path={route.path} element={e} />;
          })
        }
      </Route>
      <Route path="/" element={<ProtectedRoute />}>
        {
          protectedRoutes.map((route, index) => {
            const e =  <AppRoute routeKey={route.key} component={route.component} {...route.meta} />;
            return <Route key={route.key + index} path={route.path} element={e} />;
          })
        }
        <Route path="*" exact={true} element={<Error404 />} />
      </Route>
    </Routes>
  );
};

export default SiteMap;
