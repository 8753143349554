import React from 'react'
import {APP_NAME} from 'configs/AppConfig';
import {useTranslation} from "react-i18next";

export default function Footer() {
  const {t} = useTranslation('footer');

  return (
    <footer className="footer">
			<span>
				&copy;&nbsp;<span
        className="font-weight-semibold">{`${APP_NAME}`},&nbsp;{`${new Date().getFullYear()}`}.</span> {t('rights')}</span>
      <div>
        <a className="text-gray" href="/#" onClick={e => e.preventDefault()}>{t('terms')}</a>
        <span className="mx-2 text-muted">&nbsp;|&nbsp;</span>
        <a className="text-gray" href="/#" onClick={e => e.preventDefault()}>{t('privacy')}</a>
        <span className="mx-2 text-muted">&nbsp;|&nbsp;</span>
        <a className="text-gray" href="/#" onClick={e => e.preventDefault()}>{t('cookies')}</a>
      </div>
    </footer>
  )
}

