import {API, Methods} from "store/middlewares/apiMiddleware";
export const UserAPI = {};

const SLICE = 'user';
const ACTIONS = {
  create: 'create',
  delete: 'delete',
  update: 'update',
  list:   'list',
  item:   'item',
  search: 'search',
  passwd: 'passwd'
};

UserAPI.create = (data, onSuccess, onFailure) => {
  return {
    type:     API,
    url:      `/user`,
    method:   Methods.POST,
    data:     data,
    slice:     SLICE,
    action:    ACTIONS.create,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

UserAPI.delete = (ids, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/user`,
    method:    Methods.DELETE,
    data:      {ids},
    slice:     SLICE,
    action:    ACTIONS.delete,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

UserAPI.update = (data, onSuccess, onFailure) => {
  return {
    type:     API,
    url:      `/user`,
    method:   Methods.PUT,
    data:     data,
    slice:     SLICE,
    action:    ACTIONS.update,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

UserAPI.list = (data, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/users`,
    method:    Methods.GET,
    data:      data,
    slice:     SLICE,
    action:    ACTIONS.list,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

UserAPI.item = (id, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/user`,
    method:    Methods.GET,
    data:      {id},
    slice:     SLICE,
    action:    ACTIONS.item,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

UserAPI.search = (name, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/users`,
    method:    Methods.POST,
    data:      {name},
    slice:     SLICE,
    action:    ACTIONS.search,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

UserAPI.passwd = (data, onSuccess, onFailure) => {
  return {
    type:      API,
    url:       `/user/passwd`,
    method:    Methods.POST,
    data:      data,
    slice:     SLICE,
    action:    ACTIONS.search,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};
