import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// import antdEnUS from 'antd/es/locale/en_US';
import antdRuRU from 'antd/es/locale/ru_RU';

// import en from 'locales/english.json';
import ru from 'locales/russian.json';
import en from 'locales/english.json';

import antdEnUS from "antd/es/locale/en_US";

export const DEFAULT_LANGUAGE = 'en';

export const languages = [
  // { id: "en", name: "English", icon: "en", translation: en, antd: antdEnUS },
  {id: "ru", name: "Russian", icon: "ru", translation: ru, antd: antdRuRU},
  {id: "en", name: "English", icon: "us", translation: en, antd: antdEnUS},
];

export const resources = {
  en: en,
  ru: ru,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    keySeparator: false,
    interpolation: {escapeValue: false},
    resources: resources,
    react: {
      useSuspense: true,
    }
  });

export default i18n;
