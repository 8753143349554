import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	streams: [],
	stream: null,
	search: [],

	creating: false,
	deleting: false,
	updating: false,
	restoring: false,
	gettingList: false,
	gettingItem: false,
	searching: false,

	isError: false,
	errorCode: 0,
	errorMessage: null,
};

export const StreamSlice = createSlice({
	name: 'stream',
	initialState,
	reducers: {

		clearStreamList: (state, action) => {
			return { ...state, streams: [] };
		},

		clearStreamItem: (state, action) => {
			return { ...state, stream: null };
		},

		clearStreamSearchResult: (state, action) => {
			return { ...state, search: [] };
		},

		onLoadCreate: (state, action ) => {
			return {...state, creating: true};
		},

		onLoadDelete: (state, action ) => {
			return {...state, deleting: true};
		},

		onLoadRestore: (state, action ) => {
			return {...state, restoring: true};
		},

		onLoadArchive: (state, action ) => {
			return {...state, deleting: true};
		},

		onLoadUpdate: (state, action ) => {
			return {...state, updating: true};
		},

		onLoadList: (state, action ) => {
			return {...state, gettingList: true};
		},

		onLoadItem: (state, action ) => {
			return {...state, gettingItem: true};
		},

		onLoadSearch: (state, action ) => {
			return {...state, search: [], searching: true};
		},

		// Success
		onSuccessCreate: (state, action) => {
			return {...state, creating: false};
		},

		onSuccessDelete: (state, action) => {
			return {...state, deleting: false};
		},

		onSuccessRestore: (state, action) => {
			return {...state, restoring: false};
		},

		onSuccessUpdate: (state, action) => {
			return {...state, updating: false};
		},

		onSuccessList: (state, action) => {
			return {...state, streams: action.payload.data.data, gettingList: false};
		},

		onSuccessItem: (state, action) => {
			return {...state, stream: action.payload.data, gettingItem: false};
		},

		onSuccessSearch: (state, action) => {
			return {...state, search: action.payload.data, searching: false};
		},

		// Errors
		onFailCreate: (state, action) => {
			return {...state, creating: false };
		},
		onFailDelete: (state, action) => {
			return {...state, deleting: false };
		},
		onFailRestore: (state, action) => {
			return {...state, restoring: false };
		},
		onFailUpdate: (state, action) => {
			return {...state, updating: false };
		},
		onFailList: (state, action) => {
			return {...state, streams: [] };
		},
		onFailItem: (state, action) => {
			return {...state, stream: null };
		},
		onFailSearch: (state, action) => {
			return { ...state, search: [], searching: false };
		},
	},
});

export const {
	clearStreamItem,
	clearStreamList,
	clearStreamSearchResult
} = StreamSlice.actions;
