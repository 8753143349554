import {lazy} from "react";
import RouteAccess from "constants/RouteAccess";

export const ErrorRoutes = [
  {
    key: 'error404',
    path: `/errors/404`,
    component: lazy(() => import('views/Errors/Error404')),
    access: RouteAccess.PUBLIC,
  },
];
