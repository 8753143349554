import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  sortColumn: null,
  sortOrder: null,
};

export const SortSlice = createSlice({
  name: 'sort',
  initialState,
  reducers: {

    setSortColumn: (state, action ) => {
      return { ...state, sortColumn: action.payload };
    },

    setSortOrder: (state, action ) => {
      return { ...state, sortOrder: action.payload };
    },

    resetSort: (state, action) => {
      return { ...state, sortColumn: null, setSortOrder: null };
    },
  },
});

export const {
  setSortColumn,
  setSortOrder,
  resetSort
} = SortSlice.actions;
