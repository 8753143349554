import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	uid: null,
	token: localStorage.getItem('token'),
	checkin: true,
	isError: false,
	errorCode: 0,
	errorMessage: null,
};

export const AuthSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		onRegister: (state, action) => {
			return { ...state, ...action.payload };
		},
		onConfirm: (state, action) => {
			return { ...state, ...action.payload };
		},
		onSuccessLogin: (state, action) => {
			return { ...state, uid: action.payload.data.uid };
		},
		onLogin: (state, action) => {
			return { ...state, ...action.payload };
		},
		onRecovery: (state, action) => {
			return { ...state, ...action.payload };
		},
		onReset: (state, action) => {
			return { ...state, ...action.payload };
		},
		onSuccessLogout: (state, action) => {
			localStorage.removeItem('token');
			window.location = '/';
			return { ...state, ...action.payload };
		},
		onLogout: (state, action) => {
			//
			return { ...state, ...action.payload };
		},
		clearError: (state, action) => {
			return { ...state, isError: false, errorCode: 0, errorMessage: null };
		},
	},
});

export const {
	onRegister,
	onConfirm,
	onLogin,
	onRecovery,
	onReset,
	onLogout,
	clearError,
} = AuthSlice.actions;
