import {API, Methods} from "store/middlewares/apiMiddleware";
export const AuthAPI = {};

const SLICE = 'auth';
const ACTIONS = {
  register: 'register',
  confirm: 'confirm',
  login: 'login',
  recovery: 'recovery',
  reset: 'reset',
  logout: 'logout',
};

AuthAPI.register = (name, email, password, password_confirmation, onSuccess, onFailure) => {
  return {
    type: API,
    url: `/auth/register`,
    method: Methods.POST,
    data: {name, email, password, password_confirmation},
    slice: SLICE,
    action: ACTIONS.register,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

AuthAPI.confirm = (email_verification_code, onSuccess, onFailure) => {
  return {
    type: API,
    url: `/auth/confirm`,
    method: Methods.POST,
    data: {email_verification_code},
    slice: SLICE,
    action: ACTIONS.confirm,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

AuthAPI.login = (email, password, onSuccess, onFailure) => {
  return {
    type: API,
    url: `/auth/login`,
    method: Methods.POST,
    data: {email, password},
    slice: SLICE,
    action: ACTIONS.login,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

AuthAPI.recovery = (email, onSuccess, onFailure) => {
  return {
    type: API,
    url: `/auth/recovery`,
    method: Methods.POST,
    data: {email},
    slice: SLICE,
    action: ACTIONS.recovery,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

AuthAPI.reset = (email_verification_code, password, password_confirmation, onSuccess, onFailure) => {
  return {
    type: API,
    url: `/auth/reset`,
    method: Methods.POST,
    data: {
      email_verification_code,
      password,
      password_confirmation
    },
    slice: SLICE,
    action: ACTIONS.reset,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};

AuthAPI.logout = (onSuccess, onFailure) => {
  return {
    type: API,
    url: `/auth/logout`,
    method: Methods.POST,
    data: {
      token: localStorage.getItem('token'),
    },
    slice: SLICE,
    action: ACTIONS.logout,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
};


