import {AuthRoutes} from "./Routes/AuthRoutes";
import {OthersRoutes} from './Routes/OthersRoutes';
import {ErrorRoutes} from "./Routes/ErrorRoutes";
import {ProfileRoutes} from "./Routes/ProfileRoutes";

export const AllRoutes = [
  ...ErrorRoutes,
  ...AuthRoutes,
  ...ProfileRoutes,
  ...OthersRoutes,
];
