import {slices} from './slices';
import {configureStore} from '@reduxjs/toolkit';
import apiMiddleware from "store/middlewares/apiMiddleware";

const middlewares = [
	apiMiddleware,
];

const store = configureStore({
	reducer: slices,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		}).concat(middlewares),
	devTools: process.env.NODE_ENV === 'development',
});

export default store;