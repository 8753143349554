import React, {useEffect} from 'react';
import {ConfigProvider} from 'antd';
import {useSelector} from 'react-redux';
import {languages} from 'i18n';
import useBodyClass from 'hooks/useBodyClass';
import SiteMap from "../routes";
import antdEnUS from 'antd/es/locale/en_US';

export const Views = () => {
  const locale = useSelector(state => state.theme.locale);
  const direction = useSelector(state => state.theme.direction);
  const currentAppLocale = languages.filter(item => item.id === locale);

  useBodyClass(`dir-${direction}`);

  useEffect(() => {
    if (window !== undefined) {
      const root = window.document.documentElement;
      root.setAttribute('dir', direction);
    }
  }, [direction]);

  return (
    <ConfigProvider direction={direction} locale={currentAppLocale[0].antd || antdEnUS}>
      <SiteMap/>
    </ConfigProvider>
  )
}

export default Views;